import React from "react";
import constants from "../../../constants";
/**--------Props--------
 * None
 */

class Output extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //used for the refresh button
      counter: 0,
      showConsole: true
    };
    this.firstLoad = true;
  }

  //==============React Lifecycle Functions===================//
  shouldComponentUpdate = (nextProps, nextState) => {
    if (this.state.showConsole !== nextState.showConsole) {
      return true;
    }

    if (this.props.mostRecentProgram !== nextProps.mostRecentProgram) {
      this.firstLoad = true;
      return true;
    }
    if (nextProps.isDragging !== this.props.isDragging) {
      return true;
    }
    if (this.props.isSmall !== nextProps.isSmall) {
      return true;
    }

    if (
      this.props.project.run !== nextProps.project.run ||
      this.state.counter !== nextState.counter ||
      this.state.showConsole !== nextState.showConsole
    ) {
      this.firstLoad = false;
      return true;
    }
    return false;
  };

  // a bit hacky, but we're re-rendering the output
  // by updating the state in a novel way
  reRenderOutput = () => {
    this.setState(prevState => ({
      counter: prevState.counter + 1,
    }));
  };

    // Execute the Python code and fetch the data
    executePythonCode = async () => {
      const codeContent = this.props.project.codes[this.props.project.current];
      const body = JSON.stringify({ code: codeContent });

      try {
        const response = await fetch(
          "https://beacondev.genieacademy.com/api/beaconapp/interpret-python-code/",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: body,
          },
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const json = await response.json();
        document.getElementById("python-output").innerText = json["output"];
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Execute the Javascript code and fetch the data
    executeJavaScriptCode = async () => {
      const codeContent = this.props.project.codes[this.props.project.current];
      const body = JSON.stringify({ code: codeContent });
  
      try {
          const response = await fetch(`${constants.SERVER_URL}/evaluate`, {
              method: "POST",
              headers: {
                  "Content-Type": "application/json",
              },
              body: body,
          });
  
          if (!response.ok) {
              const errorResponse = await response.json();
              throw new Error(JSON.stringify(errorResponse)); // Throw the entire error response
          }
  
          const json = await response.json();
          document.getElementById("js-output").innerText = json.output;
      } catch (error) {
          console.error("Error fetching data:", error);
  
          let formattedError;
          try {
              // Attempt to parse the error message
              const errorDetails = JSON.parse(error.message);
              formattedError = [
                  `Error: ${errorDetails.error.message}`,
                  `Stack:`,
                  ...errorDetails.error.stack
              ].join('\n').trim();
          } catch (e) {
              // Fallback in case parsing fails
              formattedError = "An error occurred while processing the error message.";
          }
  
          // Display the formatted error in the output element
          document.getElementById("js-output").innerText = formattedError;
      }
  };  
    
    renderOutput = () => {
      //check if getsrcdoc is a function
      let codeURL = "";
      const { project, uid } = this.props;

      if (project && uid && project.files) {
        const currentLang = project.langs[project.current];

        if (currentLang === "html" || currentLang === 'css') {
          const file_display = currentLang === 'html' ? project.current : 0;
          codeURL = `${constants.SERVER_URL}/static/${uid}/Temp/${project.files[file_display]}`;
        }else if (currentLang === "js") {
          // Execute JavaScript code
          this.executeJavaScriptCode();
        } else if (currentLang === "py") {
          // For Python, execute the code using CodeExecutor
          this.executePythonCode();
        }
      }

      if (project.langs && project.langs[project.current] === "py") {
        return (
          <div style={{ background: "#FFFFFF", height: "100%" }}>
            <pre>
              <div id="python-output" className="container-fluid"></div>
            </pre>
          </div>
        );
      }else if (project.langs && project.langs[project.current] === "js") {
        return (
          <div style={{ background: "#FFFFFF", height: "100%" }}>
            <pre>
              <div id="js-output" className="container-fluid"></div>
            </pre>
        </div>
        );
      } else {
        return (
          <iframe
            id={`${this.state.counter} ${project.run}`}
            key={`${this.state.counter} ${project.run}`}
            className={`editor-output-iframe 
            ${
              this.props.isDragging && this.props.isDragging === true
                ? "notInteractive"
                : "isInteractive"
            }`}
            src={codeURL}
            title="output-iframe"
            ref="output-iframe"
            onLoad={e => {}}
          />
        );
      }
    };

  render() {
    return <div className="editor-output">{this.renderOutput()}</div>;
  }
}

export default Output;
